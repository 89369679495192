import React from "react"
import { Redirect } from "react-router-dom"
// Profile
import UserProfile from "../pages/Authentication/user-profile"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// CA Marketing
import Users from "pages/Hasini/Users"
import Customers from "pages/Hasini/Customers"

import Banner from "pages/Hasini/Event"
import Eventgellary from "pages/Hasini/Eventgellary"
import Dishes from "pages/Hasini/Dishes"
import Testimonials from "pages/Hasini/Testimonials"
import Leads from "pages/Hasini/Leads"
import Faq from "pages/Hasini/Faq"
import Contact from "pages/Hasini/Contact"

import Termsandconditions from "pages/Hasini/TermsandConditions"
import Privacy from "pages/Hasini/PrivacyPolicy"
import Refering from "pages/Hasini/ReferingPolicy"
import Withdraw from "pages/Hasini/Withdraw"
import Customerdetails from "pages/Hasini/Customerdetails"
import Userdetails from "pages/Hasini/Userdetails"
import Cities from "pages/Hasini/Cities"
import Aboutus from "pages/Hasini/Aboutus"

// Dashboard
import Dashboard from "../pages/Dashboards/index"
import Otp from "pages/Authentication/Otp"
import Setpwd from "pages/Authentication/Setpwd"

//Bookings
import PendingBookings from "pages/Hasini/Bookings/PendingBookings"
import CancelledBookings from "pages/Hasini/Bookings/CancelledBookings"
import CompletedBookings from "pages/Hasini/Bookings/CompletedBookings"
import ViewBooking from "pages/Hasini/Bookings/ViewBooking"

import Coupons from "pages/Hasini/Coupons"

//Payments
import Payments from "pages/Hasini/Payments/Payments"

import Category from "pages/Hasini/Category"

import Notification from "pages/Hasini/Notification"

import Banners from "pages/Hasini/Banners"

import AcceptedBookings from "pages/Hasini/Bookings/AcceptedBookings"
import OnTheWayBooking from "pages/Hasini/Bookings/OnTheWayBooking"

import BookingReport from "pages/Hasini/Reports/BookingReport"
import PaymentsReport from "pages/Hasini/Reports/PaymentsReport"

import PendingSupport from "pages/Hasini/Support/PendingSupport"
import SolvedSupport from "pages/Hasini/Support/SolvedSupport"
import UserView from "pages/Hasini/UserView"

import DeleteAccount from "../pages/DeleteAccount"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/users", component: Users },
  { path: "/users_details", component: Userdetails },
  { path: "/customers", component: Customers },
  { path: "/customer_details", component: Customerdetails },
  { path: "/events", component: Banner },
  { path: "/gallery", component: Eventgellary },
  { path: "/dishes", component: Dishes },
  { path: "/testimonials", component: Testimonials },
  { path: "/leads", component: Leads },
  { path: "/faq", component: Faq },
  { path: "/contact", component: Contact },
  { path: "/ViewBooking", component: ViewBooking },

  { path: "/UserView", component: UserView },

  { path: "/Banners", component: Banners },

  { path: "/Category", component: Category },

  //Support
  { path: "/PendingSupport", component: PendingSupport },
  { path: "/SolvedSupport", component: SolvedSupport },

  { path: "/Coupons", component: Coupons },
  { path: "/Notification", component: Notification },

  //Bookings
  { path: "/PendingBookings", component: PendingBookings },
  { path: "/CancelledBookings", component: CancelledBookings },
  { path: "/CompletedBookings", component: CompletedBookings },
  { path: "/Payments", component: Payments },

  { path: "/AcceptedBookings", component: AcceptedBookings },
  { path: "/OnTheWayBooking", component: OnTheWayBooking },

  { path: "/termsandconditions", component: Termsandconditions },
  { path: "/privacy", component: Privacy },
  { path: "/refering", component: Refering },
  { path: "/withdraw", component: Withdraw },
  { path: "/cities", component: Cities },
  { path: "/aboutus", component: Aboutus },

  // Reports
  { path: "/BookingReport", component: BookingReport },
  { path: "/PaymentsReport", component: PaymentsReport },


  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/otp", component: Otp },
  { path: "/setpassword", component: Setpwd },
  { path: "/deleteAccount", component: DeleteAccount },
]

export { publicRoutes, authProtectedRoutes }
