import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button, Table } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { addData } from "Servicescalls"
import { imgUrl } from "Baseurls"

function ViewFranchise() {
  const [Bookings, setBookings] = useState([])
  const [Items, setItems] = useState([])

  useEffect(() => {
    getBookings()
  }, [])

  const getBookings = async () => {
    const dataArray = {
      orderId: sessionStorage.getItem("BookingId"),
    }
    const resonse = await addData("customer/getOrderDetails", dataArray)
    var _data = resonse
    setBookings(_data.data?.orders[0])
    setItems(_data.data?.orders[0]?.items)
  }

  const history = useHistory()

  const convertToIST = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', { 
      timeZone: 'Asia/Kolkata',
      hour12: false 
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Hasini Enterprises"
            breadcrumbItem="View Booking"
          />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={5}>
              <Card>
                <CardBody>
                  <h5 className="mb-4 text-primary">User Profile:</h5>
                  <ul className="list-unstyled vstack gap-3 mb-0">
                    <a
                      href={imgUrl + Bookings.profilePic}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {Bookings.profilePic == "" ? (
                        <>
                          <img
                            src="https://static.vecteezy.com/system/resources/thumbnails/008/846/297/small_2x/cute-boy-avatar-png.png"
                            height="100px"
                           
                          ></img>
                        </>
                      ) : (
                        <>
                          <img
                            src={imgUrl + Bookings.profilePic}
                            height="100px"
                            width="100px"
                          ></img>
                        </>
                      )}
                    </a>
                    <li>
                      <div className="d-flex mt-3">
                        <i className="fas fa-address-book font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Name: </h6>
                          <span className="text-muted">{Bookings.name}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-2">
                        <i className="fas fa-address-card font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Email:</h6>
                          <span className="text-muted">{Bookings.email}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-2">
                        <i className="fas fa-chalkboard-teacher font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Phone:</h6>
                          {Bookings.phone}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-2">
                        <i className="fas fa-calendar-alt font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Date Of Joning:</h6>
                          {Bookings.createdAt}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-2">
                        <i className="bx bx-bookmark font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Status :</h6>
                          <span className="badge bg-primary">
                            {Bookings.status}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col lg={7}>
              <Card>
                <CardBody>
                  <h5 className="mb-4 text-primary">Payment Status:</h5>
                  <div className="table-responsive">
                    <Table className="table table-bordered">
                      <thead>
                        <tr className="text-center">
                          <th>Payment Type</th>
                          <td>{Bookings.paymentMethod}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Transaction Id</th>
                          <td>{Bookings.transactionId}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Delivery Fee</th>
                          <td>{Bookings.deliveryFee}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Coupon Name</th>
                          <td>
                            {Bookings.couponName == "" ? (
                              "-"
                            ) : (
                              <>{Bookings.couponName} </>
                            )}
                          </td>
                        </tr>
                        <tr className="text-center">
                          <th>Coupon Code</th>
                          <td>
                            {Bookings.couponCode == "" ? (
                              "-"
                            ) : (
                              <>{Bookings.couponCode} </>
                            )}
                          </td>
                        </tr>
                        <tr className="text-center">
                          <th>Coupon Discount </th>
                          <td>{Bookings.discount}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Sub Amount</th>
                          <td>{Bookings.subTotal}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Total Amount</th>
                          <td>{Bookings.totalPrice}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Payment Status</th>
                          <td>
                            <span className="badge bg-primary">
                              {Bookings.paymentStatus}
                            </span>
                          </td>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    {" "}
                    <Col md={6}>
                      <div className="table-responsive">
                        <h5 className="mb-4 mt-3 text-primary">
                          Order Details:
                        </h5>
                        <Table className="table table-bordered mb-4 mt-3">
                          <thead>
                            <tr className="text-center">
                              <th>Booking Id</th>
                              <td>{Bookings.bookingId}</td>
                            </tr>
                            <tr className="text-center">
                              <th>User Id</th>
                              <td>{Bookings.userId}</td>
                            </tr>
                            <tr className="text-center">
                              <th>Booking Status</th>
                              <td>{Bookings.status}</td>
                            </tr>
                          </thead>
                        </Table>
                      </div>
                    </Col>
                    <Col md={6}>
                      <h5 className="mb-4 mt-3 text-primary">
                        Delivery Address:
                      </h5>
                      <h6>
                        <td> {Bookings.deliveryAddress}</td>
                      </h6>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h5 className="mb-4 text-primary">Items Details:</h5>
                  <div className="table-responsive">
                    <Table className="table table-bordered mb-4">
                      <thead className="text-center">
                        <tr>
                          <th>SNo</th>
                          <th>Dish Name</th>
                          <th>Dish Image</th>
                          <th>Quantity</th>
                          <th>Dish Price</th>
                          <th>Dish Offer Price</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {Items?.map((data, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{data.dishName} </td>
                            <td>
                              <img
                                style={{ width: "100px" }}
                                src={imgUrl + data.dishes.image}
                              />
                            </td>
                            <td>{data.quantity}</td>
                            <td>{data.dishes.price}</td>
                            <td>{data.dishes.offerPrice}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* adding */}
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h5 className="mb-4 text-primary">Delivery Boy Details:</h5>
                  <div className="table-responsive">
                    <Table className="table table-bordered mb-4">
                      <thead className="text-center">
                        <tr>
                        <th>SNo</th>
                        <th>Date and Time</th>
                        <th>Delivery Boy Name</th>
                          <th>Delivery Boy Mobile Number</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                      <tr>
                      <td>{1}</td>
                      <td>{convertToIST(Bookings.logCreatedDate)}</td>
                      <td>{Bookings.deliveryBoyName} </td>
                            <td>{Bookings.deliveryBoyPhone}</td>
                          </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewFranchise
