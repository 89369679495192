import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Input, Table, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import toast, { Toaster } from "react-hot-toast"
import { addData } from "Servicescalls"
import { useHistory } from "react-router-dom"

const Users = () => {
  const [User, setUser] = useState([])

  useEffect(() => {
    GetAll()
  }, [])

  const GetAll = async () => {
    const resonse = await addData("customer/getUsers")
    var _data = resonse
    setUser(_data.data.users)
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = User.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(User.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [search, setsearch] = useState([])

  const searchAll = async e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)
    const resonse = await addData(
      "customer/getUsers?searchQuery=" + e.target.value
    )
    var _data = resonse
    setUser(_data.data.users)
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to InActive?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = async data => {
    const dataArray = { customerId: data._id }
    try {
      const resonse = await addData("customer/customerStatusUpdate", dataArray)
      var _data = resonse
      toast.success(_data.data.message)
      GetAll()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      }
    }
  }

  const manageDelete1 = data => {
    const confirmBox = window.confirm("Do you really want to Active?")
    if (confirmBox === true) {
      Delete1(data)
    }
  }

  const Delete1 = async data => {
    const dataArray = { customerId: data._id }
    try {
      const resonse = await addData("customer/customerStatusUpdate", dataArray)
      var _data = resonse
      toast.success(_data.data.message)
      GetAll()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      }
    }
  }

  const history = useHistory()
  const viewid = data => {
    history.push("/UserView", sessionStorage.setItem("UserId", data._id))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Hasini Enterprises" breadcrumbItem="Customers" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col></Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin mt-4">
                    <Table hover bordered responsive>
                      <thead>
                        <tr>
                          <th>Sl.No</th>
                          <th>User Name</th>
                          <th>User Email</th>
                          <th>User Mobile No</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <th scope="row">
                              {(pageNumber - 1) * 5 + key + 6}
                            </th>
                            <td>{data.name}</td>
                            <td>{data.email}</td>
                            <td>{data.phone}</td>
                            <td>
                              {data.status == true ? (
                                <span className="badge bg-primary">Active</span>
                              ) : (
                                <span className="badge bg-danger">
                                  InActive
                                </span>
                              )}
                            </td>
                            <td>
                              {/* <Button
                                onClick={() => {
                                  viewid(data)
                                }}
                                size="sm"
                                className="m-1"
                                color="info"
                              >
                                <i
                                  className="fas fa-eye"
                                  style={{ fontSize: " 14px" }}
                                ></i>
                              </Button> */}
                              {data.status == true ? (
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  outline
                                  color="danger"
                                >
                                  <i
                                    style={{ fontSize: " 14px" }}
                                    className="fas fa-user-lock"
                                  ></i>
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    manageDelete1(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  outline
                                  color="success"
                                >
                                  <i
                                    style={{ fontSize: " 14px" }}
                                    className="fas fa-user-check"
                                  ></i>
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Col sm="12">
                      <div
                        className="d-flex mt-3 mb-1"
                        style={{ float: "right" }}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Users
