import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Table,
  Button,
  Modal,
  Form,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import { toast, ToastContainer } from "react-toastify"
import { addData } from "Servicescalls"
import accept from "../../../assets/images/accept.gif"
import trash from "../../../assets/images/trash.gif"
import { useHistory } from "react-router-dom"

const Users = () => {
  const [modal_small, setmodal_small] = useState(false)

  const [modal_small1, setmodal_small1] = useState(false)

  const [User, setUser] = useState([])

  useEffect(() => {
    GetAll()
  }, [])

  const GetAll = async () => {
    const dataArray = {
      status: "on_the_way",
    }
    const resonse = await addData("customer/getAllPendingOrders", dataArray)
    var _data = resonse
    setUser(_data.data.orders)
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = User.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(User.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [search, setsearch] = useState([])

  const searchAll = async e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)
    const dataArray = {
      status: "on_the_way",
    }
    const resonse = await addData(
      "customer/getAllPendingOrders?searchQuery=" + e.target.value,
      dataArray
    )
    var _data = resonse
    setUser(_data.data.orders)
  }

  const [form1, setform1] = useState([])

  const handleChange = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const Edit = async e => {
    e.preventDefault()
    const dataArray = {
      orderId: form1._id,
      status: form1.statuss,
    }

    try {
      const resonse = await addData("customer/approveOrder", dataArray)
      var _data = resonse
      toast(_data.data.message)
      GetAll()
      setmodal_small(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const [form2, setform2] = useState([])

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const getpopup1 = data => {
    setform2(data)
    tog_small1()
  }

  const handleChange1 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  const Edit1 = async e => {
    e.preventDefault()
    const dataArray = {
      orderId: form2._id,
      rejectedReason: form2.reason,
      rejectedAt: "pending",
      status: "cancelled",
    }

    try {
      const resonse = await addData("customer/approveOrder", dataArray)
      var _data = resonse
      toast(_data.data.message)
      GetAll()
      setmodal_small1(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  
  const history = useHistory()
  const viewid = data => {
    history.push("/ViewBooking", sessionStorage.setItem("BookingId", data._id))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Hasini Enterprises"
            breadcrumbItem="On The Way Bookings "
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col></Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin mt-4">
                    <Table hover bordered responsive>
                      <thead>
                        <tr className="text-center">
                          <th>S.No</th>
                          <th>Booking Id</th>
                          <th>User Id</th>
                          <th>User Name</th>
                          <th>Phone</th>
                          <th className="text-start">Item Name</th>
                          <th>Payment Status</th>
                          <th>Total Price</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.bookingId}</td>
                            <td>{data.userId}</td>
                            <td>{data.name}</td>
                            <td>{data.phone}</td>
                            <td className="text-start">
                              {data.items.map((datas, i) => (
                                <p key={i}>
                                  {i + 1}) {datas.dishName}
                                </p>
                              ))}
                            </td>
                            <td>{data.paymentStatus}</td>
                            <td>{data.totalPrice}</td>
                            <td>
                              {data.status == "completed" ? (
                                <span className="badge bg-primary">
                                  {data.status}
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  {data.status}
                                </span>
                              )}
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  getpopup(data)
                                }}
                                className="m-1"
                                color="success"
                                size="sm"
                              >
                                <i className="bx bxs-select-multiple"></i>
                                Completed
                              </Button>
                              <Button
                                onClick={() => {
                                  viewid(data)
                                }}
                                size="sm"
                                className="m-1"
                                color="info"
                              >
                                <i className="fas fa-eye"></i>
                                View
                              </Button>
                              <Button
                                onClick={() => {
                                  getpopup1(data)
                                }}
                                className="m-1"
                                color="danger"
                                size="sm"
                              >
                                <i className="bx bxs-help-circle"></i> Reject
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Col sm="12">
                      <div
                        className="d-flex mt-3 mb-1"
                        style={{ float: "right" }}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
      <Modal
        size="md"
        centered
        isOpen={modal_small}
        toggle={() => {
          tog_small()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Accept Bookings
          </h5>
          <button
            onClick={() => {
              setmodal_small(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={e => {
              Edit(e)
            }}
          >
            <Row>
              <Col md="12">
                <img style={{ width: "100%" }} src={accept} />
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input3">
                    Booking Status <span className="text-danger">*</span>
                  </Label>

                  <select
                    value={form1.statuss}
                    name="statuss"
                    required
                    onChange={e => {
                      handleChange(e)
                    }}
                    id="ss"
                    className="form-select"
                  >
                    <option value="">Select</option>
                    <option value="delivered">Completed</option>
                  </select>
                </div>
              </Col>
            </Row>

            <div style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small(false)
                }}
                color="danger"
                type="button"
              >
                Cancel <i className="fas fa-times-circle"></i>
              </Button>
              <Button className="m-1" color="success" type="submit">
                Submit <i className="fas fa-check-circle"></i>
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        size="md"
        centered
        isOpen={modal_small1}
        toggle={() => {
          tog_small1()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Reject Bookings
          </h5>
          <button
            onClick={() => {
              setmodal_small1(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={e => {
              Edit1(e)
            }}
          >
            <Row>
              <Col md="12">
                <img style={{ width: "100%" }} src={trash} />
              </Col>
            </Row>

            <Col md={12}>
              <div className="mb-3">
                <label>
                  Reason <span className="text-danger">*</span>
                </label>
                <textarea
                  type="text"
                  rows="3"
                  className="form-control "
                  id="basicpill-firstname-input1"
                  placeholder="Enter Reason"
                  required
                  value={form2.reason}
                  name="reason"
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>
            </Col>

            <div style={{ float: "right" }} className="mt-3">
              <Button
                onClick={() => {
                  setmodal_small1(false)
                }}
                color="danger"
                type="button"
              >
                Cancel <i className="fas fa-times-circle"></i>
              </Button>
              <Button className="m-1" color="success" type="submit">
                Submit <i className="fas fa-check-circle"></i>
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Users
