import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { addData } from "Servicescalls"
import { imgUrl } from "Baseurls"

function ViewFranchise() {
  const [Bookings, setBookings] = useState([])

  useEffect(() => {
    getBookings()
  }, [])

  const getBookings = async () => {
    const dataArray = {
      orderId: sessionStorage.getItem("UserId"),
    }
    const resonse = await addData("customer/getOrderDetails", dataArray)
    var _data = resonse
    setBookings(_data.data?.orders)
  }

  const history = useHistory()

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Hasini Enterprises" breadcrumbItem="View User" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col md={5}>
                  <h5 className="mb-4 text-primary">User Profile:</h5>
                  <ul className="list-unstyled vstack gap-3 mb-0">
                    <a
                      href={imgUrl + Bookings.profilePic}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {Bookings.profilePic == "" ? (
                        <>
                          <img
                            src="https://static.vecteezy.com/system/resources/thumbnails/008/846/297/small_2x/cute-boy-avatar-png.png"
                            height="100px"
                          ></img>
                        </>
                      ) : (
                        <>
                          <img
                            src={imgUrl + Bookings.profilePic}
                            height="100px"
                            width="100px"
                          ></img>
                        </>
                      )}
                    </a>
                    <li>
                      <div className="d-flex mt-3">
                        <i className="fas fa-address-book font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Name: </h6>
                          <span className="text-muted">{Bookings.name}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-2">
                        <i className="fas fa-address-card font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Email:</h6>
                          <span className="text-muted">{Bookings.email}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-2">
                        <i className="fas fa-chalkboard-teacher font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Phone:</h6>
                          {Bookings.phone}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-2">
                        <i className="fas fa-calendar-alt font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Date Of Joning:</h6>
                          {Bookings.createdAt}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-2">
                        <i className="bx bx-bookmark font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Status :</h6>
                          <span className="badge bg-primary">
                            {Bookings.status}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col md={7}>
                  <h5 className="mb-4 text-primary">Booking Status:</h5>
                  <div className="table-responsive">
                    <Table className="table table-bordered">
                      <thead>
                        <tr className="text-center">
                          <th>Total Bookings</th>
                          <td>{Bookings.paymentMethod}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Total Payments</th>
                          <td>{Bookings.paymentMethod}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Pending Bookings</th>
                          <td>{Bookings.transactionId}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Completed Bookings</th>
                          <td>{Bookings.deliveryFee}</td>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewFranchise
