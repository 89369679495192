import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { addData } from "Servicescalls"
import ReactPaginate from "react-paginate"

const Dashboard = props => {
  const [form, setform] = useState([])

  const [customers, setcustomers] = useState([])

  const [dash, setdash] = useState([])

  const [Bookings, setBookings] = useState([])

  const getAlldata = async () => {
    const resonse = await addData("dashboard/getLeads", {})
    var _data = resonse
    setform(_data.data)
  }

  const getAlldatas = async () => {
    const resonse = await addData("dashboard/getDashboard", {})
    var _data = resonse
    setdash(_data.data)
    setBookings(_data.data.latestUsers)
  }

  const getAlldata1 = async () => {
    const resonse = await addData("dashboard/getAllenquiry", {})
    var _data = resonse
    setcustomers(_data.data.enquiry)
  }

  useEffect(() => {
    getAlldata()
    getAlldata1()
    getAlldatas()
  }, [])

  const reports = [
    {
      title: "Total Leads",
      iconClass: "bx-copy-alt",
      description: form.totalEnquiry,
    },
    {
      title: "Today Leads",
      iconClass: "bx-archive-in",
      description: form.todayEnquiry,
    },
    {
      title: "Total Events",
      iconClass: "bx bx-user-check",
      description: form.totalEvents,
    },
  ]

  const reports1 = [
    {
      title: "Total Bookings",
      iconClass: "bx-copy-alt",
      description: dash.totalOrders,
    },
    {
      title: "Total Payments",
      iconClass: "bx-archive-in",
      description: dash.totalPayments,
    },
    {
      title: "Pending Bookings",
      iconClass: "bx bx-user-check",
      description: dash.pendingOrders,
    },
    {
      title: "Completed Bookings",
      iconClass: "bx-copy-alt",
      description: dash.completedOrders,
    },
  ]

  const periodData1 = [
    {
      name: "Completed Bookings",
      data: dash.orderStats,
    },
    {
      name: "Total Amount ",
      data: dash.paymentStats,
    },
  ]
  const options1 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "34%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },

    colors: ["#8CC200", "#4C994C"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: " (data)",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Hasini Enterprises"}
            breadcrumbItem={"Dashboard"}
          />

          <Row>
            <Col xl="12">
              <Row>
                {reports1.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Row>
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Row>
                <Col lg="12">
                  {" "}
                  <Card>
                    <CardBody>
                      <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title mb-4">Bookings / Payments</h4>
                      </div>
                      <ReactApexChart
                        options={options1}
                        series={[...periodData1]}
                        type="bar"
                        height="359"
                        className="apex-charts"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div>
                <Card>
                  <CardBody>
                    <h5 className="mt-3 mb-5">Latest Customers</h5>
                    <div className="table-rep-plugin mt-4">
                      <Table hover bordered responsive>
                        <thead className="bg-light">
                          <tr>
                            <th>Sl.No</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>User Mobile No</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Bookings.map((data, key) => (
                            <tr key={key}>
                              <th scope="row">{key + 1}</th>
                              <td>{data.name}</td>
                              <td>{data.email}</td>
                              <td>{data.phone}</td>
                              <td>
                                {data.status == true ? (
                                  <span className="badge bg-primary">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge bg-danger">
                                    InActive
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col lg="12">
              <div>
                <Card>
                  <CardBody>
                    <h5 className="mt-3 mb-5">Latest Leads</h5>
                    <div className="table-rep-plugin mt-4">
                      <Table hover bordered responsive>
                        <thead className="bg-light">
                          <tr>
                            <th>Sl No</th>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile No</th>
                            <th>Event Type</th>
                            <th>State</th>
                            <th>City</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customers.map((data, key) => (
                            <tr key={key}>
                              <th scope="row">{key + 1}</th>
                              <td>{data.date}</td>
                              <td>{data.enterYourName}</td>
                              <td>{data.enterYourEmail}</td>
                              <td>{data.enterContactNumber}</td>
                              <td>{data.eventType}</td>
                              <td>{data.selectState}</td>
                              <td>{data.selectCity}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
