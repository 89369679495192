import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import Select from "react-select"
import { baseurl } from "../../Baseurls"

const Notifications = () => {
  const [form, setform] = useState([])

  const [Noti, setNoti] = useState([])

  const [customer, setcustomer] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const getNotifications = () => {
    var token = datas
    axios
      .post(
        baseurl + "notification/getNotifications",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNoti(res.data.notifications)
      })
  }

  useEffect(() => {
    getNotifications()
    getactivecustomers()
  }, [])

  const getactivecustomers = () => {
    var token = datas

    axios
      .post(
        baseurl + "customer/getUsers",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setcustomer(res.data.activeUsers)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [selectedMulti, setselectedMulti] = useState([])

  function handleMulti(data) {
    setselectedMulti(data)
  }

  const options = customer.map(data => ({
    value: data._id,
    label: data.name,
  }))

  const addnotifi = () => {
    var token = datas

    const dataArray = {
      title: form.title,
      userList: form.userList == "All" ? "All" : selectedMulti,
      description: form.description,
    }

    axios
      .post(baseurl + "notification/sendNotification", dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getNotifications()
            clearForm()
            setselectedMulti("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const deletenoti = data => {
    var token = datas

    axios
      .post(
        baseurl + "notification/deleteNotification",
        { id: data._id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getNotifications()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletenoti(data)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    addnotifi()
  }

  const clearForm = () => {
    setform({
      title: "",
      description: "",
      userList: "",
    })
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Noti.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Noti.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Hasini Enterprises"
            breadcrumbItem="Notification"
          />
          <Row>
            <Col md={4}>
              <Card className="p-4">
                <h5>Add Notification</h5>
                <Form
                  onSubmit={e => {
                    handleSubmit(e)
                  }}
                >
                  <div>
                    <div className="mt-3">
                      <Label>Title</Label>
                      <span className="text-danger">*</span>
                      <Input
                        value={form.title}
                        onChange={e => {
                          handleChange(e)
                        }}
                        name="title"
                        required
                        type="text"
                        placeholder="Enter Title"
                      />
                    </div>
                    <div className="mt-3">
                      <Label for="basicpill-firstname-input1">
                        User
                        <span className="text-danger">*</span>
                      </Label>

                      <select
                        value={form.userList}
                        name="userList"
                        onChange={e => {
                          handleChange(e)
                        }}
                        className="form-select"
                        required
                      >
                        <option value="">Select</option>
                        <option value="All">All User's</option>
                        <option value="USER">Single User</option>
                      </select>
                    </div>
                    {form.userList == "USER" ? (
                      <div className="mt-3">
                        <Label>User</Label>
                        <span className="text-danger">*</span>
                        <Select
                          value={selectedMulti}
                          onChange={handleMulti}
                          options={options}
                          required
                          isMulti
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="mt-3">
                      <Label>Description</Label>
                      <span className="text-danger">*</span>
                      <textarea
                        className="form-control"
                        value={form.description}
                        onChange={e => {
                          handleChange(e)
                        }}
                        name="description"
                        required
                        type="text"
                        placeholder="Description"
                      />
                    </div>
                  </div>

                  <div className="text-end mt-3">
                    <Button type="submit" color="primary">
                      Submit <i className="bx bx-check-circle"></i>
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardBody>
                  <h5> Notification List</h5>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover bordered responsive>
                      <thead>
                        <tr>
                          <th>Sl.No</th>
                          <th>Date/Time</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <th scope="row">
                              {(pageNumber - 1) * 5 + key + 6}
                            </th>
                            <td>
                              {data.date} / {data.time}
                            </td>
                            <td>{data.title}</td>
                            <td>{data.description}</td>
                            <td>
                              <Button
                                onClick={() => {
                                  manageDelete(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="danger"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-trash"
                                ></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="mt-3" style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Notifications
