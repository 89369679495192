import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Input, Table, Button } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import { ToastContainer } from "react-toastify"
import { addData } from "Servicescalls"
import { useHistory } from "react-router-dom"

const Users = () => {
  const [User, setUser] = useState([])

  useEffect(() => {
    GetAll()
  }, [])

  const GetAll = async () => {
    const dataArray = {
      status: "cancelled",
    }
    const resonse = await addData("customer/getAllPendingOrders", dataArray)
    var _data = resonse
    setUser(_data.data.orders)
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = User.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(User.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [search, setsearch] = useState([])

  const searchAll = async e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)
    const dataArray = {
      status: "cancelled",
    }
    const resonse = await addData(
      "customer/getAllPendingOrders?searchQuery=" + e.target.value,
      dataArray
    )
    var _data = resonse
    setUser(_data.data.orders)
  }

  const history = useHistory()
  const viewid = data => {
    history.push("/ViewBooking", sessionStorage.setItem("BookingId", data._id))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Hasini Enterprises"
            breadcrumbItem="Cancelled Bookings "
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col></Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin mt-4">
                    <Table hover bordered responsive>
                      <thead>
                        <tr className="text-center">
                          <th>S.No</th>
                          <th>Booking Id</th>
                          <th>User Id</th>
                          <th>User Name</th>
                          <th>Phone</th>
                          <th className="text-start">Item Name</th>
                          <th>Payment Status</th>
                          <th>Total Price</th>
                          <th>Status</th>
                          <th>Reason</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.bookingId}</td>
                            <td>{data.userId}</td>
                            <td>{data.name}</td>
                            <td>{data.phone}</td>
                            <td className="text-start">
                              {data.items.map((datas, i) => (
                                <p key={i}>
                                  {i + 1}) {datas.dishName}
                                </p>
                              ))}
                            </td>
                            <td>{data.paymentStatus}</td>
                            <td>{data.totalPrice}</td>
                            <td>
                              {data.status == "completed" ? (
                                <span className="badge bg-primary">
                                  {data.status}
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  {data.status}
                                </span>
                              )}
                            </td>
                            <td>{data.rejectedReason}</td>
                            <td>
                              <Button
                                onClick={() => {
                                  viewid(data)
                                }}
                                size="sm"
                                className="m-1"
                                color="info"
                              >
                                <i className="fas fa-eye"></i>View
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Col sm="12">
                      <div
                        className="d-flex mt-3 mb-1"
                        style={{ float: "right" }}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Users
