import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
import { getAllData, addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import Select from "react-select"

const Dishes = () => {
  const [banner, setbanner] = useState([])
  const [form, setform] = useState({
    name: "",
    price: "",
    rating: "",
    offerPrice: "",
    description: "",
    isPopular: "",
    isVeg: false,
  })
  const [form1, setform1] = useState([])

  const [Category, setCategory] = useState([])

  const [Adddishes, setAdddishes] = useState(false)

  const hideAdddishes = () => setAdddishes(false)

  const [Editdishes, setEditdishes] = useState(false)

  const hideEditdishes = () => setEditdishes(false)

  const [Files, setFiles] = useState({ image: "" })
  const [Files1, setFiles1] = useState({ image: "" })

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (
      type == "jpg" ||
      type == "jpeg" ||
      type == "png" ||
      type == "JPG" ||
      type == "JPEG" ||
      type == "PNG"
    ) {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast.error("Please select a valid image file (jpg, jpeg, or png)")
    }
  }
  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (
      type == "jpg" ||
      type == "jpeg" ||
      type == "png" ||
      type == "JPG" ||
      type == "JPEG" ||
      type == "PNG"
    ) {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast.error("Please select a valid image file (jpg, jpeg, or png)")
    }
  }

  const [selectedOptions, setSelectedOptions] = useState([])

  const Categoryss = selectedOptions => {
    setSelectedOptions(selectedOptions)
  }

  const options = Category.map(response => ({
    value: response._id,
    label: response.category_name,
  }))

  const [selectedOptions1, setSelectedOptions1] = useState([])

  const Categoryss1 = selectedOptions1 => {
    setSelectedOptions1(selectedOptions1)
  }

  const options1 = Category.map(response => ({
    value: response._id,
    label: response.category_name,
  }))

  function tog_small() {
    setEditdishes(!Editdishes)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const getAllbenners = async () => {
    const resonse = await getAllData("dish/getAlldish")
    var _data = resonse
    setbanner(_data.data.Dishes)
  }

  useEffect(() => {
    getAllbenners()
    GetAllCategorys()
  }, [])

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Add = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("name", form.name)
    dataArray.append("price", form.price)
    dataArray.append("rating", form.rating)
    dataArray.append("offerPrice", form.offerPrice)
    dataArray.append("description", form.description)
    dataArray.append("isVeg", form.isVeg)
    dataArray.append("isPopular", form.isPopular)
    dataArray.append("categoryId", JSON.stringify(selectedOptions))
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("image", Files[i])
    }
    try {
      const resonse = await addData("dish/adddish", dataArray)
      var _data = resonse
      console.log(_data)
      setFiles({ image: "" })
      setform({
        name: "",
        price: "",
        rating: "",
        offerPrice: "",
        description: "",
        isPopular: "",
        isVeg: false,
      })
      setSelectedOptions("")
      toast.success(_data.data.message)
      getAllbenners()
      hideAdddishes()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const Edit = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("name", form1.name)
    dataArray.append("price", form1.price)
    dataArray.append("rating", form1.rating)
    dataArray.append("offerPrice", form1.offerPrice)
    dataArray.append("description", form1.description)
    dataArray.append("isPopular", form1.isPopular)
    dataArray.append("isVeg", form1.isVeg)
    dataArray.append("categoryId", JSON.stringify(selectedOptions1))
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("image", Files1[i])
    }
    try {
      const resonse = await updateData("dish/editdish/" + form1._id, dataArray)
      var _data = resonse
      setFiles1({ image: "" })
      toast.success(_data.data.message)
      hideEditdishes()
      getAllbenners()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      }
    }
  }

  const deletebenners = async data => {
    try {
      const resonse = await deletedData("dish/deletedish/" + data._id, {})
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllbenners()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }

  const getpopup = data => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setform1(data)
    setSelectedOptions1(data.categories)
    tog_small()
  }

  const handleSearch = async e => {
    const resonse = await getAllData(
      "dish/getAlldish?searchQuery=" + e.target.value
    )
    var _data = resonse
    setbanner(_data.data.Dishes)
  }

  const GetAllCategorys = async () => {
    const resonse = await addData("category/getCategories")
    var _data = resonse
    setCategory(_data.data.categories)
  }

  const handleChange1s = e => {
    const myUser = { ...form }
    myUser[e.target.name] = e.target.checked
    setform(myUser)
  }

  const handleChange2s = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.checked
    setform1(myUser)
  }

  const manageDeletes = data => {
    const confirmBox = window.confirm("Do you really want to InActive?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = async data => {
    const dataArray = { dishId: data._id, status: "inactive" }
    try {
      const resonse = await addData("dish/dishUpdateStatus", dataArray)
      var _data = resonse
      toast.success(_data.data.message)
      getAllbenners()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      }
    }
  }

  const manageDelete1 = data => {
    const confirmBox = window.confirm("Do you really want to Active?")
    if (confirmBox === true) {
      Delete1(data)
    }
  }

  const Delete1 = async data => {
    const dataArray = { dishId: data._id, status: "active" }
    try {
      const resonse = await addData("dish/dishUpdateStatus", dataArray)
      var _data = resonse
      toast.success(_data.data.message)
      getAllbenners()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Hasini Enterprises" breadcrumbItem="Dishes" />
          <Row>
            {Adddishes ? (
              <>
                <Col md={12}>
                  <Card>
                    <CardHeader className="bg-white">
                      <CardTitle>Add Dish</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form
                        onSubmit={e => {
                          Add(e)
                        }}
                      >
                        <Row>
                          <Col md="4">
                            <div className="mb-3 ">
                              <Label for="basicpill-firstname-input1">
                                Category
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                options={options}
                                placeholder="Enter Categorys"
                                value={selectedOptions}
                                onChange={Categoryss}
                                isSearchable={true}
                                isMulti
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Dish Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Dish Name"
                                required
                                name="name"
                                value={form.name}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Dish Image
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="file"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter image"
                                required
                                name="image"
                                accept=".jpg, .jpeg, .png"
                                value={Files.image}
                                onChange={changeHandler}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Dish Price
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Price"
                                required
                                name="price"
                                value={form.price}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Dish Offer Price
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Dish Offer Price"
                                required
                                name="offerPrice"
                                value={form.offerPrice}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3 mt-4">
                              <Label for="basicpill-firstname-input1">
                                IS Veg :
                              </Label>
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                name="isVeg"
                                defaultChecked={form.isVeg}
                                value={form.isVeg}
                                onClick={e => {
                                  handleChange1s(e)
                                }}
                                style={{ fontSize: "18px", marginLeft: "10px" }}
                                id="read"
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Select Rating
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                className="form-select"
                                required
                                name="rating"
                                value={form.rating}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              >
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="1.5">1.5</option>
                                <option value="2">2</option>
                                <option value="2.5">2.5</option>
                                <option value="3">3</option>
                                <option value="3.5">3.5</option>
                                <option value="4">4</option>
                                <option value="4.5">4.5</option>
                                <option value="5">5</option>
                              </select>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                IS Popular
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                className="form-select"
                                required
                                name="isPopular"
                                value={form.isPopular}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                Description
                              </Label>
                              <span className="text-danger">*</span>
                              <textarea
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter Description"
                                name="description"
                                value={form.description}
                                onChange={e => {
                                  handleChange(e)
                                }}
                                required
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="mt-4" style={{ float: "right" }}>
                          <Button
                            className="m-1"
                            onClick={hideAdddishes}
                            color="danger"
                            type="button"
                          >
                            Cancel <i className="bx bx-x-circle"></i>
                          </Button>
                          <Button className="m-1" color="success" type="submit">
                            Submit <i className="fas fa-check-circle"></i>
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}

            {Editdishes ? (
              <>
                <Col md={12}>
                  <Card>
                    <CardHeader className="bg-white">
                      <CardTitle>Edit Dish</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form
                        onSubmit={e => {
                          Edit(e)
                        }}
                      >
                        <Row>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Category
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                options={options1}
                                placeholder="Enter Categorys"
                                value={selectedOptions1}
                                onChange={Categoryss1}
                                isSearchable={true}
                                isMulti
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Dish Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Dish Name"
                                required
                                name="name"
                                value={form1.name}
                                onChange={e => {
                                  handleChange1(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Dish Image
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="file"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter image"
                                name="image"
                                accept=".jpg, .jpeg, .png"
                                value={Files1.image}
                                onChange={changeHandler1}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Dish Price
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Price"
                                required
                                name="price"
                                value={form1.price}
                                onChange={e => {
                                  handleChange1(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Dish Offer Price
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Dish Offer Price"
                                required
                                name="offerPrice"
                                value={form1.offerPrice}
                                onChange={e => {
                                  handleChange1(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3 mt-4">
                              <Label for="basicpill-firstname-input1">
                                IS Veg :
                              </Label>
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                name="isVeg"
                                defaultChecked={form1.isVeg}
                                value={form1.isVeg}
                                onClick={e => {
                                  handleChange2s(e)
                                }}
                                style={{ fontSize: "18px", marginLeft: "10px" }}
                                id="read"
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                IS Popular
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                className="form-select"
                                required
                                name="isPopular"
                                value={form1.isPopular}
                                onChange={e => {
                                  handleChange1(e)
                                }}
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Select Rating
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                className="form-select"
                                required
                                name="rating"
                                value={form1.rating}
                                onChange={e => {
                                  handleChange1(e)
                                }}
                              >
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="1.5">1.5</option>
                                <option value="2">2</option>
                                <option value="2.5">2.5</option>
                                <option value="3">3</option>
                                <option value="3.5">3.5</option>
                                <option value="4">4</option>
                                <option value="4.5">4.5</option>
                                <option value="5">5</option>
                              </select>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                Description
                              </Label>
                              <span className="text-danger">*</span>
                              <textarea
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter Description"
                                name="description"
                                value={form1.description}
                                onChange={e => {
                                  handleChange1(e)
                                }}
                                required
                              />
                            </div>
                          </Col>
                        </Row>

                        <div className="mt-4" style={{ float: "right" }}>
                          <Button
                            className="m-1"
                            onClick={hideEditdishes}
                            color="danger"
                            type="button"
                          >
                            Cancel <i className="bx bx-x-circle"></i>
                          </Button>
                          <Button className="m-1" color="success" type="submit">
                            Submit <i className="fas fa-check-circle"></i>
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}

            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Button
                        className="m-1"
                        onClick={() => {
                          setAdddishes(!Adddishes)
                        }}
                        color="primary"
                      >
                        <i className="fas fa-filter"></i> Add Dish
                      </Button>
                    </Col>
                    <Col md="6">
                      <div style={{ float: "right" }}>
                        <Input
                          type="text"
                          name="search"
                          onChange={handleSearch}
                          className="form-control"
                          placeholder="Search.."
                        />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-4 mt-3">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Category Name</th>
                            <th>Dish Name</th>
                            <th>Dish Image</th>
                            <th>Dish Price</th>
                            <th>Dish Offer Price</th>
                            <th>Dish Rating </th>
                            <th>IS Veg</th>
                            <th>IS Popular</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <td> {(pageNumber - 1) * 5 + key + 6}</td>
                              <td>
                                {data.categories.map((data, i) => (
                                  <>
                                    <p key={i}>
                                      {i + 1}){data.label}
                                    </p>
                                  </>
                                ))}{" "}
                              </td>
                              <td>{data.name} </td>
                              <td>
                                <img
                                  style={{ width: "100px" }}
                                  src={imgUrl + data.image}
                                />
                              </td>
                              <td>{data.price}</td>
                              <td>{data.offerPrice}</td>
                              <td>{data.rating}</td>
                              <td>
                                {data.isVeg == true ? <>Yes</> : <> No</>}
                              </td>
                              <td>{data.isPopular}</td>
                              <td>{data.description}</td>
                              <td>{data.status}</td>
                              <td>
                                <Button
                                  onClick={() => {
                                    getpopup(data)
                                  }}
                                  className="mr-2"
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="success"
                                  outline
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>

                                {data.status == "active" ? (
                                  <Button
                                    onClick={() => {
                                      manageDeletes(data)
                                    }}
                                    outline
                                    style={{ padding: "6px", margin: "3px" }}
                                    color="danger"
                                  >
                                    <i className="fas fa-user-lock"></i>
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      manageDelete1(data)
                                    }}
                                    outline
                                    style={{ padding: "6px", margin: "3px" }}
                                    color="success"
                                  >
                                    <i className="fas fa-user-check"></i>
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Dishes
