import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Table,
  Button,
  Modal,
  Form,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import { toast, ToastContainer } from "react-toastify"
import { addData } from "Servicescalls"
import accept from "../../../assets/images/verification-img.png"
import { imgUrl } from "Baseurls"

const Users = () => {
  const [modal_small, setmodal_small] = useState(false)

  const [User, setUser] = useState([])

  useEffect(() => {
    GetAll()
  }, [])

  const GetAll = async () => {
    const dataArray = {
      status: "open",
    }
    const resonse = await addData("getQueries", dataArray)
    var _data = resonse
    setUser(_data.data.data)
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = User.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(User.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [search, setsearch] = useState([])

  const searchAll = async e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)
    const dataArray = {
      status: "open",
    }
    const resonse = await addData(
      "getQueries?searchQuery=" + e.target.value,
      dataArray
    )
    var _data = resonse
    setUser(_data.data.data)
  }

  const [form1, setform1] = useState([])

  const handleChange = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const Edit = async e => {
    e.preventDefault()
    const dataArray = {
      userId: form1.usersId,
      reply: form1.reply,
      queryId: form1._id,
    }

    try {
      const resonse = await addData("replyQueries", dataArray)
      var _data = resonse
      toast(_data.data.message)
      GetAll()
      setmodal_small(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Hasini Enterprises"
            breadcrumbItem="Pending Support"
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col></Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin mt-4">
                    <Table hover bordered responsive>
                      <thead>
                        <tr className="text-center">
                          <th>S.No</th>
                          <th>Ticket Id</th>
                          <th>User Id</th>
                          <th>User Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Image</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.requestId}</td>
                            <td>{data.userId}</td>
                            <td>{data.name}</td>
                            <td>{data.email}</td>
                            <td>{data.phone}</td>
                            <td>
                              <img
                                style={{ width: "100px" }}
                                src={imgUrl + data.image}
                              />
                            </td>
                            <td>{data.message}</td>
                            <td>
                              {data.status == "completed" ? (
                                <span className="badge bg-primary">
                                  {data.status}
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  {data.status}
                                </span>
                              )}
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  getpopup(data)
                                }}
                                className="m-1"
                                color="success"
                                size="sm"
                              >
                                <i className="bx bxs-select-multiple"></i>{" "}
                                Resolved
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Col sm="12">
                      <div
                        className="d-flex mt-3 mb-1"
                        style={{ float: "right" }}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
      <Modal
        size="md"
        centered
        isOpen={modal_small}
        toggle={() => {
          tog_small()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Status Update
          </h5>
          <button
            onClick={() => {
              setmodal_small(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={e => {
              Edit(e)
            }}
          >
            <Row>
              <Col md="12">
                <img style={{ width: "100%", height: "400px" }} src={accept} />
              </Col>

              <Col md="12">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input2">Reply</Label>
                  <span className="text-danger">*</span>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input2"
                    placeholder="Enter Reply"
                    name="reply"
                    value={form1.reply}
                    onChange={e => {
                      handleChange(e)
                    }}
                    required
                  />
                </div>
              </Col>
            </Row>
            <div style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small(false)
                }}
                color="danger"
                type="button"
              >
                Cancel <i className="fas fa-times-circle"></i>
              </Button>
              <Button className="m-1" color="success" type="submit">
                Submit <i className="fas fa-check-circle"></i>
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Users
